import { useForm } from "react-hook-form";
import { ContentSection } from "./article-form/ContentSection";
import { CategorySection } from "./article-form/CategorySection";
import { SEOSection } from "./article-form/SEOSection";
import { PublishSection } from "./article-form/PublishSection";
import { FormActions } from "./article-form/FormActions";
import { ArticleFormData } from "./article-form/types";
import { useArticleImageUpload } from "@/hooks/useArticleImageUpload";
import { useArticleSubmit } from "@/hooks/useArticleSubmit";

interface ArticleFormProps {
  initialData?: ArticleFormData;
}

export const ArticleForm = ({ initialData }: ArticleFormProps) => {
  const { register, handleSubmit, setValue, watch } = useForm<ArticleFormData>({
    defaultValues: {
      hidden: false,
      status: "draft",
      ...initialData,
    },
  });

  const { uploading, handleImageUpload } = useArticleImageUpload(setValue);
  const { isSubmitting, onSubmit } = useArticleSubmit(initialData);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 bg-white p-6 rounded-lg shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <ContentSection
            register={register}
            watch={watch}
            setValue={setValue}
            handleImageUpload={handleImageUpload}
            uploading={uploading}
          />
        </div>

        <div className="space-y-8">
          <CategorySection setValue={setValue} initialSubcategoryId={initialData?.subcategory_id?.toString()} />
          <SEOSection register={register} setValue={setValue} watch={watch} />
          <PublishSection watch={watch} setValue={setValue} />
        </div>
      </div>

      <FormActions isSubmitting={isSubmitting} isEditing={!!initialData} />
    </form>
  );
};