import { Separator } from "@/components/ui/separator";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Youtube } from "lucide-react";
import { useTheme } from "@/hooks/useTheme";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";

export const Footer = () => {
  const { theme } = useTheme();
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .order("name", { ascending: true });
      
      if (!error && data) {
        setCategories(data);
      }
    };
    
    fetchCategories();
  }, []);
  
  return (
    <footer className="bg-background border-t">
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-start space-y-4">
            <h3 className="text-lg font-bold mb-2 text-foreground">À Propos</h3>
            <p className="text-muted-foreground text-left">
              Fan2Moto est votre source d'actualités et d'informations sur l'univers de la moto.
            </p>
            <img 
              src="/lovable-uploads/e7019a85-8589-4a14-b5af-64acffdcc95a.png" 
              alt="Fan2Moto Logo" 
              className="h-6 w-auto mt-4"
            />
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 text-foreground">Catégories</h3>
            <ul className="space-y-2">
              {categories.map((category) => (
                <li key={category.id}>
                  <Link 
                    to={`/?category=${category.slug}`} 
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 text-foreground">Suivez-nous</h3>
            <div className="flex flex-col space-y-4">
              <div className="flex space-x-4">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-foreground transition-colors">
                  <Facebook className="h-5 w-5" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-foreground transition-colors">
                  <Instagram className="h-5 w-5" />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-foreground transition-colors">
                  <Youtube className="h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <Separator className="my-8" />
        
        <div className="flex flex-col items-center space-y-4">
          <a 
            href="https://feelinx.dev/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex flex-col items-center space-y-2 group"
          >
            <img 
              src={theme === 'dark' ? "/lovable-uploads/6457af58-6167-4db6-b4f3-752f4c8399f0.png" : "/lovable-uploads/819bdbdf-b90e-4866-97a3-374c01b38415.png"} 
              alt="Feelinx Logo" 
              className="h-6 w-auto transition-opacity group-hover:opacity-80"
            />
            <span className="text-sm text-muted-foreground group-hover:text-foreground transition-colors">
              Powered by Feelinx
            </span>
          </a>
          
          <p className="text-sm text-muted-foreground">
            © 2024 Fan2Moto. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};