import { Toaster } from "@/components/ui/toaster";
import { useState, useEffect } from "react";
import { CategoryFilter } from "@/components/CategoryFilter";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { MainContent } from "@/components/home/MainContent";
import { useArticles } from "@/hooks/useArticles";
import { useSearchParams } from "react-router-dom";
import { Moon, Sun } from "lucide-react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { useTheme } from "@/hooks/useTheme";
import { StickyBanner } from "@/components/home/StickyBanner";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    searchParams.get("category")
  );
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { theme, toggleTheme } = useTheme();
  const { toast } = useToast();

  const { articles, totalPages, isLoading } = useArticles(
    selectedCategory,
    selectedSubcategoryId,
    currentPage
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from("categories")
        .select("name")
        .order("name");

      if (error) {
        toast({
          title: "Erreur",
          description: "Impossible de charger les catégories",
          variant: "destructive",
        });
        return;
      }

      setCategories(data.map((cat) => cat.name));
    };

    fetchCategories();
  }, [toast]);

  const handleCategorySelect = (category: string | null) => {
    setSelectedCategory(category);
    setSelectedSubcategoryId(null);
    setCurrentPage(1);
    
    if (category) {
      setSearchParams({ category });
    } else {
      setSearchParams({});
    }
  };

  const handleSubcategorySelect = (subcategoryId: string) => {
    setSelectedSubcategoryId(subcategoryId);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-background text-foreground"
    >
      <div className="fixed top-0 left-0 right-0 z-50 bg-background">
        <div className="container mx-auto flex items-center justify-between h-14 px-4">
          <div className="flex items-center gap-2">
            <img 
              src="/lovable-uploads/e7019a85-8589-4a14-b5af-64acffdcc95a.png" 
              alt="Fan2Moto Logo" 
              className="h-4 md:h-6 object-contain cursor-pointer"
              onClick={() => handleCategorySelect(null)}
            />
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleTheme}
            className="hidden md:inline-flex rounded-full hover:bg-accent"
          >
            <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">Changer le thème</span>
          </Button>
        </div>

        <nav className="bg-magazine-red text-white">
          <div className="container mx-auto">
            <CategoryFilter
              categories={categories}
              selectedCategory={selectedCategory}
              onSelectCategory={handleCategorySelect}
              onSelectSubcategory={handleSubcategorySelect}
            />
          </div>
        </nav>
      </div>

      <div className="pt-28">
        <MainContent
          selectedCategory={selectedCategory}
          articles={articles}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <StickyBanner />
      <Toaster />
    </motion.div>
  );
};

export default Home;
