import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { ArticleFormData } from "./types";
import { supabase } from "@/integrations/supabase/client";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";

interface CategorySectionProps {
  setValue: UseFormSetValue<ArticleFormData>;
  initialSubcategoryId?: string;
}

interface Category {
  id: number;
  name: string;
  subcategories: Array<{
    id: number;
    name: string;
  }>;
}

export const CategorySection = ({ setValue, initialSubcategoryId }: CategorySectionProps) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data: categoriesData, error: categoriesError } = await supabase
          .from("categories")
          .select(`
            id,
            name,
            subcategories (
              id,
              name
            )
          `)
          .order('name', { ascending: true });

        if (categoriesError) throw categoriesError;

        console.log("Fetched categories with subcategories:", categoriesData);
        setCategories(categoriesData || []);
        
        // If there's an initial subcategory, find its category and select it
        if (initialSubcategoryId) {
          const categoryWithInitialSubcat = categoriesData?.find(
            cat => cat.subcategories.some(sub => sub.id.toString() === initialSubcategoryId)
          );
          if (categoryWithInitialSubcat) {
            setSelectedCategory(categoryWithInitialSubcat.id);
            setValue("subcategory_id", parseInt(initialSubcategoryId, 10));
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast({
          title: "Erreur",
          description: "Impossible de charger les catégories",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [toast, setValue, initialSubcategoryId]);

  const handleCategoryChange = (categoryId: string) => {
    const numericCategoryId = parseInt(categoryId, 10);
    setSelectedCategory(numericCategoryId);
    // Reset subcategory when category changes
    setValue("subcategory_id", 0);
  };

  const selectedCategoryData = categories.find(cat => cat.id === selectedCategory);

  return (
    <Card className="bg-white border border-gray-200">
      <CardHeader>
        <CardTitle className="text-gray-700">Catégorie</CardTitle>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center py-4">
            <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
          </div>
        ) : categories.length === 0 ? (
          <div className="text-center text-gray-500 py-4">
            Aucune catégorie disponible
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium text-gray-700 mb-2 block">
                Sélectionner une catégorie
              </label>
              <Select
                onValueChange={handleCategoryChange}
                defaultValue={selectedCategory?.toString()}
              >
                <SelectTrigger className="bg-white border-gray-200">
                  <SelectValue placeholder="Choisir une catégorie" />
                </SelectTrigger>
                <SelectContent>
                  {categories.map((category) => (
                    <SelectItem key={category.id} value={category.id.toString()}>
                      {category.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {selectedCategory && (
              <div>
                <label className="text-sm font-medium text-gray-700 mb-2 block">
                  Sélectionner une sous-catégorie
                </label>
                {selectedCategoryData?.subcategories.length ? (
                  <Select
                    onValueChange={(value) => setValue("subcategory_id", parseInt(value, 10))}
                    defaultValue={initialSubcategoryId}
                  >
                    <SelectTrigger className="bg-white border-gray-200">
                      <SelectValue placeholder="Choisir une sous-catégorie" />
                    </SelectTrigger>
                    <SelectContent>
                      {selectedCategoryData.subcategories.map((subcat) => (
                        <SelectItem key={subcat.id} value={subcat.id.toString()}>
                          {subcat.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                ) : (
                  <div className="text-sm text-gray-500">
                    Aucune sous-catégorie disponible pour cette catégorie
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
