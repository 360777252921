import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { useImageCompression } from "@/hooks/useImageCompression";

export const useArticleImageUpload = (setValue: (field: string, value: any) => void) => {
  const [uploading, setUploading] = useState(false);
  const { toast } = useToast();
  const { uploadImage } = useImageCompression({
    bucketName: "ui_images",
    folderPath: "articles",
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploading(true);
    try {
      const publicUrl = await uploadImage(file);
      setValue('featured_image', publicUrl);
      toast({
        title: "Succès",
        description: "Image téléchargée avec succès",
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      toast({
        title: "Erreur",
        description: "Impossible de télécharger l'image",
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  return {
    uploading,
    handleImageUpload,
  };
};