import { motion } from "framer-motion";
import { ArticleCard } from "../ArticleCard";
import { Separator } from "../ui/separator";

interface Article {
  id: string;
  title: string;
  imageUrl: string;
  category: string;
  subcategory: string;
}

interface FeaturedSectionProps {
  articles: Article[];
}

export const FeaturedSection = ({ articles }: FeaturedSectionProps) => {
  const topArticles = articles.slice(0, 3);
  const secondRowArticles = articles.slice(3, 5); // Take 2 articles for second row
  const leftGridArticles = articles.slice(5, 11);
  const rightArticles = articles.slice(11, 13);
  const bottomArticles = articles.slice(13, 16);

  return (
    <div className="py-8">
      <div className="mb-2">
        <h2 className="text-2xl font-bold text-foreground text-left">
          À la Une
        </h2>
        <Separator className="bg-magazine-red h-1 mt-2" />
      </div>
      
      {/* Top 3 articles grid */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        {topArticles.map((article) => (
          <motion.div
            key={article.id}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="h-full"
          >
            <ArticleCard
              id={article.id}
              title={article.title}
              imageUrl={article.imageUrl}
              category={article.category}
              subcategory={article.subcategory}
            />
          </motion.div>
        ))}
      </div>

      {/* Second row - 2 articles side by side */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        {secondRowArticles.map((article) => (
          <motion.div
            key={article.id}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="h-full"
          >
            <ArticleCard
              id={article.id}
              title={article.title}
              imageUrl={article.imageUrl}
              category={article.category}
              subcategory={article.subcategory}
            />
          </motion.div>
        ))}
      </div>

      {/* Grid layout for remaining articles */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        {/* Left grid - 6 articles */}
        <div className="sm:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
          {leftGridArticles.map((article) => (
            <motion.div
              key={article.id}
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.2 }}
              className="h-full"
            >
              <ArticleCard
                id={article.id}
                title={article.title}
                imageUrl={article.imageUrl}
                category={article.category}
                subcategory={article.subcategory}
                isCompact={true}
              />
            </motion.div>
          ))}
        </div>

        {/* Right column - 2 articles */}
        <div className="sm:col-span-2 grid grid-cols-1 gap-4">
          {rightArticles.map((article) => (
            <motion.div 
              key={article.id} 
              className="h-full"
              whileHover={{ scale: 1.01 }}
              transition={{ duration: 0.2 }}
            >
              <ArticleCard
                id={article.id}
                title={article.title}
                imageUrl={article.imageUrl}
                category={article.category}
                subcategory={article.subcategory}
                isCompact={false}
              />
            </motion.div>
          ))}
        </div>
      </div>

      {/* Bottom articles */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
        {bottomArticles.map((article) => (
          <motion.div
            key={article.id}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            className="h-full"
          >
            <ArticleCard
              id={article.id}
              title={article.title}
              imageUrl={article.imageUrl}
              category={article.category}
              subcategory={article.subcategory}
            />
          </motion.div>
        ))}
      </div>
      <Separator className="bg-magazine-red h-1 mt-8" />
    </div>
  );
};