import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { Edit2, Save, Trash2 } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

interface SubcategoriesTableProps {
  subcategories: any[];
  onDelete: (id: string) => void;
  onRefresh: () => void;
}

export const SubcategoriesTable = ({ subcategories, onDelete, onRefresh }: SubcategoriesTableProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [subcategoryToDelete, setSubcategoryToDelete] = useState(null);
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const { toast } = useToast();

  const confirmDelete = (subcategory) => {
    setSubcategoryToDelete(subcategory);
    setDeleteDialogOpen(true);
  };

  const handleDelete = () => {
    if (!subcategoryToDelete) return;
    onDelete(subcategoryToDelete.id);
    setDeleteDialogOpen(false);
    setSubcategoryToDelete(null);
  };

  const handleEdit = async (subcategory) => {
    if (editingSubcategory?.id === subcategory.id) {
      const { error } = await supabase
        .from("subcategories")
        .update({ name: editingSubcategory.name, slug: editingSubcategory.slug })
        .eq("id", subcategory.id);

      if (error) {
        toast({
          title: "Erreur",
          description: "Impossible de modifier la sous-catégorie",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Succès",
        description: "Sous-catégorie modifiée avec succès",
      });

      setEditingSubcategory(null);
      onRefresh();
    } else {
      setEditingSubcategory(subcategory);
    }
  };

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Nom</TableHead>
                <TableHead>Slug</TableHead>
                <TableHead>Catégorie</TableHead>
                <TableHead className="w-[180px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {subcategories.map((subcategory) => (
                <TableRow key={subcategory.id}>
                  <TableCell>
                    {editingSubcategory?.id === subcategory.id ? (
                      <Input
                        value={editingSubcategory.name}
                        onChange={(e) =>
                          setEditingSubcategory({
                            ...editingSubcategory,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      subcategory.name
                    )}
                  </TableCell>
                  <TableCell>
                    {editingSubcategory?.id === subcategory.id ? (
                      <Input
                        value={editingSubcategory.slug}
                        onChange={(e) =>
                          setEditingSubcategory({
                            ...editingSubcategory,
                            slug: e.target.value,
                          })
                        }
                      />
                    ) : (
                      subcategory.slug
                    )}
                  </TableCell>
                  <TableCell>{subcategory.categories?.name}</TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleEdit(subcategory)}
                      >
                        {editingSubcategory?.id === subcategory.id ? (
                          <Save className="h-4 w-4" />
                        ) : (
                          <Edit2 className="h-4 w-4" />
                        )}
                      </Button>
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() => confirmDelete(subcategory)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
            <AlertDialogDescription>
              Cette action est irréversible. Cela supprimera définitivement la sous-catégorie
              "{subcategoryToDelete?.name}".
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annuler</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Supprimer</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
};