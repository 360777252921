import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";

interface ArticleTableProps {
  articles: any[];
  onEdit: (id: string) => void;
  onDelete: (article: any) => void;
  onToggleHidden: (id: string, currentValue: boolean) => void;
}

export const ArticleTable = ({
  articles,
  onEdit,
  onDelete,
  onToggleHidden,
}: ArticleTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-50">
          <TableHead className="text-gray-700">Title</TableHead>
          <TableHead className="text-gray-700">Category</TableHead>
          <TableHead className="text-gray-700">Status</TableHead>
          <TableHead className="text-gray-700">Hidden</TableHead>
          <TableHead className="text-gray-700">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {articles.map((article) => (
          <TableRow key={article.id} className="border-gray-100">
            <TableCell className="text-gray-700">{article.title}</TableCell>
            <TableCell className="text-gray-700">
              {article.subcategories?.category?.name} / {article.subcategories?.name}
            </TableCell>
            <TableCell>
              <span className="px-2 py-1 text-sm rounded-full bg-green-50 text-green-700 border border-green-200">
                {article.status || "draft"}
              </span>
            </TableCell>
            <TableCell>
              <Switch
                checked={article.hidden}
                onCheckedChange={() => onToggleHidden(article.id, article.hidden)}
              />
            </TableCell>
            <TableCell className="space-x-2">
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => onEdit(article.id)}
                className="bg-white border-gray-200 hover:bg-gray-50 text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                >
                  <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
                  <path d="m15 5 4 4" />
                </svg>
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onDelete(article)}
                className="bg-red-50 hover:bg-red-100 border-red-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4 text-red-600"
                >
                  <path d="M3 6h18" />
                  <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                  <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                </svg>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};