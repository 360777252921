import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

interface BannerSettings {
  background_url: string;
  background_type: string;
  link_url: string | null;
}

export const Banner = () => {
  const [bannerSettings, setBannerSettings] = useState<BannerSettings | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchBannerSettings = async () => {
      console.log("Fetching banner settings...");
      try {
        const { data, error } = await supabase
          .from("home_settings")
          .select("*")
          .order("created_at", { ascending: false })
          .limit(1)
          .maybeSingle();

        if (error) {
          console.error("Error fetching banner settings:", error);
          toast({
            title: "Erreur",
            description: "Impossible de charger les paramètres de la bannière",
            variant: "destructive",
          });
          return;
        }

        console.log("Banner data received:", data);
        setBannerSettings(data);
      } catch (error) {
        console.error("Error in banner component:", error);
      }
    };

    fetchBannerSettings();
  }, [toast]);

  if (!bannerSettings?.background_url) {
    console.log("No banner settings or background URL found");
    return null;
  }

  console.log("Rendering banner with URL:", bannerSettings.background_url);

  const BannerContent = () => (
    <img
      src={bannerSettings.background_url}
      alt="Banner"
      className="w-[970px] h-[250px] object-cover mx-auto"
    />
  );

  return (
    <div className="w-full flex justify-center">
      {bannerSettings.link_url ? (
        <a
          href={bannerSettings.link_url}
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <BannerContent />
        </a>
      ) : (
        <BannerContent />
      )}
    </div>
  );
};