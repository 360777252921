import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface DeleteCategoryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  categoryToDelete: any;
  onConfirm: () => void;
}

export const DeleteCategoryDialog = ({
  open,
  onOpenChange,
  categoryToDelete,
  onConfirm,
}: DeleteCategoryDialogProps) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="bg-white">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-900">Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription className="text-gray-600">
            Cette action est irréversible. Cela supprimera définitivement la catégorie
            "{categoryToDelete?.name}".
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-gray-50 text-gray-700 hover:bg-gray-100 border-gray-200">
            Annuler
          </AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm} className="bg-magazine-red text-white hover:bg-magazine-red/90">
            Supprimer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};