import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";

interface CategoryFormProps {
  onSubmit: (category: { name: string; slug: string }) => void;
}

export const CategoryForm = ({ onSubmit }: CategoryFormProps) => {
  const [newCategory, setNewCategory] = useState({ name: "", slug: "" });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(newCategory);
    setNewCategory({ name: "", slug: "" });
  };

  return (
    <Card className="bg-white shadow-sm">
      <CardContent className="pt-6">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-2">
            <label htmlFor="categoryName" className="text-sm font-medium text-gray-700">
              Nom de la catégorie
            </label>
            <Input
              id="categoryName"
              placeholder="Nom de la catégorie"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              required
              className="w-full bg-white border-gray-200"
            />
          </div>
          <div className="grid gap-2">
            <label htmlFor="categorySlug" className="text-sm font-medium text-gray-700">
              Slug
            </label>
            <Input
              id="categorySlug"
              placeholder="Slug"
              value={newCategory.slug}
              onChange={(e) =>
                setNewCategory({ ...newCategory, slug: e.target.value })
              }
              required
              className="w-full bg-white border-gray-200"
            />
          </div>
          <Button type="submit" className="w-full sm:w-auto bg-magazine-red hover:bg-magazine-red/90 text-white">
            Ajouter une catégorie
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};