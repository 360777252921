import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { ChevronDown, ChevronRight, Menu, Moon, Sun } from "lucide-react";
import { useTheme } from "@/hooks/useTheme";

interface MobileMenuProps {
  categories: string[];
  selectedCategory: string | null;
  activeSubcategory: string | null;
  subcategories: Record<string, any[]>;
  expandedCategories: string[];
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
  onCategoryClick: (category: string | null) => void;
  onSubcategoryClick: (subcategoryId: string, category: string, event: React.MouseEvent) => void;
  toggleCategory: (category: string) => void;
}

export const MobileMenu = ({
  categories,
  selectedCategory,
  activeSubcategory,
  subcategories,
  expandedCategories,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  onCategoryClick,
  onSubcategoryClick,
  toggleCategory,
}: MobileMenuProps) => {
  const allCategories = ["Tout", ...categories];
  const { theme, toggleTheme } = useTheme();

  return (
    <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="md:hidden text-white hover:bg-white/20"
        >
          <Menu className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      
      <SheetContent
        side="left"
        className="w-[280px] p-0 overflow-hidden flex flex-col bg-background dark:bg-zinc-900 border-r"
      >
        <div className="flex items-center justify-between p-4 border-b bg-magazine-red text-white">
          <div className="flex items-center gap-4">
            <h2 className="font-bold text-lg">Menu</h2>
            <Button
              variant="ghost"
              onClick={toggleTheme}
              className="text-white hover:bg-white/20 h-8 w-8 p-0"
            >
              <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            </Button>
          </div>
        </div>
        
        <div className="flex-1 overflow-y-auto">
          <div className="py-2">
            {allCategories.map((category) => (
              <div key={category} className="border-b border-border">
                <button
                  onClick={() => onCategoryClick(category === "Tout" ? null : category)}
                  className={`w-full text-left px-4 py-3 flex items-center justify-between hover:bg-magazine-red hover:text-white transition-colors ${
                    ((category === "Tout" && !selectedCategory) || 
                    (selectedCategory === category && !activeSubcategory))
                      ? "text-magazine-red font-medium dark:text-red-400"
                      : "text-foreground"
                  } ${subcategories[category]?.length ? "cursor-pointer" : ""}`}
                >
                  <span>{category}</span>
                  {category !== "Tout" && subcategories[category]?.length > 0 && (
                    expandedCategories.includes(category) ? (
                      <ChevronDown className="h-4 w-4" />
                    ) : (
                      <ChevronRight className="h-4 w-4" />
                    )
                  )}
                </button>
                
                {category !== "Tout" && subcategories[category] && expandedCategories.includes(category) && (
                  <div className="bg-accent/50 dark:bg-zinc-800">
                    {subcategories[category].map((subcategory) => (
                      <button
                        key={subcategory.id}
                        onClick={(e) => onSubcategoryClick(subcategory.id, category, e)}
                        className="w-full text-left px-6 py-2 text-sm text-foreground hover:bg-magazine-red hover:text-white transition-colors"
                      >
                        {subcategory.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};