import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { ArticleFormData } from "../components/admin/article-form/types";

export const useArticleSubmit = (initialData?: ArticleFormData) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const onSubmit = async (data: ArticleFormData) => {
    setIsSubmitting(true);
    try {
      console.log("Submitting article data:", data);
      console.log("Initial data ID:", initialData?.id);

      if (initialData?.id) {
        const { error } = await supabase
          .from("articles")
          .update({
            title: data.title,
            content: data.content,
            excerpt: data.excerpt,
            featured_image: data.featured_image,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            slug: data.slug,
            subcategory_id: data.subcategory_id,
            hidden: data.hidden,
            status: data.status,
            updated_at: new Date().toISOString(),
          })
          .eq('id', initialData.id);

        if (error) throw error;

        toast({
          title: "Succès",
          description: "Article modifié avec succès",
        });
      } else {
        const { error } = await supabase
          .from("articles")
          .insert([{
            ...data,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          }]);

        if (error) throw error;

        toast({
          title: "Succès",
          description: "Article créé avec succès",
        });
      }
      
      navigate("/admin");
    } catch (error) {
      console.error("Error submitting article:", error);
      toast({
        title: "Erreur",
        description: initialData ? "Impossible de modifier l'article" : "Impossible de créer l'article",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    onSubmit,
  };
};