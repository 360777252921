import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { VerticalBanner } from "@/components/article/VerticalBanner";
import { useIsMobile } from "@/hooks/use-mobile";
import { SocialShare } from "@/components/article/SocialShare";
import { CategoryFilter } from "@/components/CategoryFilter";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";

const Article = () => {
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [categories, setCategories] = useState<string[]>([]);

  // Fetch categories from Supabase
  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase
        .from("categories")
        .select("name")
        .order("name");

      if (error) {
        console.error("Error fetching categories:", error);
        return;
      }

      setCategories(data.map((cat) => cat.name));
    };

    fetchCategories();
  }, []);

  const { data: article, isLoading } = useQuery({
    queryKey: ["article", id],
    queryFn: async () => {
      if (!id) {
        toast({
          title: "Erreur",
          description: "ID d'article manquant",
          variant: "destructive",
        });
        throw new Error("ID d'article manquant");
      }

      const { data, error } = await supabase
        .from("articles")
        .select(`
          *,
          subcategories (
            name,
            categories (
              name
            )
          )
        `)
        .eq("id", parseInt(id, 10))
        .maybeSingle();

      if (error) {
        toast({
          title: "Erreur",
          description: "Impossible de charger l'article",
          variant: "destructive",
        });
        throw error;
      }

      return data;
    },
    enabled: !!id,
  });

  const handleCategoryClick = (category: string | null) => {
    if (category) {
      navigate(`/?category=${category}`);
    } else {
      navigate('/');
    }
  };

  const handleSubcategoryClick = (subcategoryId: string) => {
    navigate(`/?subcategory=${subcategoryId}`);
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  if (!article) {
    return <div>Article non trouvé</div>;
  }

  const currentUrl = window.location.href;
  const categoryName = article.subcategories?.categories?.name;

  return (
    <div className="min-h-screen bg-background">
      <div className="sticky top-0 z-50 bg-magazine-red">
        <CategoryFilter
          categories={categories}
          selectedCategory={null}
          onSelectCategory={handleCategoryClick}
          onSelectSubcategory={handleSubcategoryClick}
        />
      </div>
      <div className="max-w-7xl mx-auto py-4 md:py-8 px-4">
        <div className={`flex ${isMobile ? 'flex-col gap-4' : 'gap-8'}`}>
          <div className={`${isMobile ? 'w-full' : 'flex-1'}`}>
            <div className="space-y-3 md:space-y-4">
              <div className="flex items-center justify-between">
                {categoryName && (
                  <Button
                    variant="link"
                    className="text-magazine-red text-sm font-medium p-0 hover:text-magazine-red/80"
                    onClick={() => handleCategoryClick(categoryName)}
                  >
                    {categoryName}
                  </Button>
                )}
                <SocialShare
                  url={currentUrl}
                  title={article.title}
                  imageUrl={article.featured_image || ''}
                />
              </div>
              <h1 className="text-2xl md:text-4xl font-bold font-roboto text-foreground">
                {article.title}
              </h1>
              {article.excerpt && (
                <div className="text-lg md:text-xl text-muted-foreground font-medium italic border-l-4 border-magazine-red pl-4">
                  {article.excerpt}
                </div>
              )}
              {article.featured_image && (
                <div className="w-full aspect-video overflow-hidden rounded-lg">
                  <img 
                    src={article.featured_image} 
                    alt={article.title}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div className="w-[300px] shrink-0">
              <VerticalBanner />
            </div>
          )}
        </div>
        <div className="mt-8">
          <div
            className="prose dark:prose-invert max-w-none text-base md:text-lg"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </div>
        {isMobile && (
          <div className="w-full mt-8">
            <VerticalBanner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Article;