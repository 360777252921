import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { RichTextEditor } from "../RichTextEditor";
import { UseFormRegister, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { ArticleFormData } from "./types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface ContentSectionProps {
  register: UseFormRegister<ArticleFormData>;
  watch: UseFormWatch<ArticleFormData>;
  setValue: UseFormSetValue<ArticleFormData>;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  uploading: boolean;
}

export const ContentSection = ({
  register,
  watch,
  setValue,
  handleImageUpload,
  uploading,
}: ContentSectionProps) => {
  return (
    <div className="space-y-6">
      <Card className="bg-white border border-gray-200">
        <CardHeader>
          <CardTitle className="text-gray-700">Contenu de l'article</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="title" className="text-gray-700">Titre</Label>
            <Input 
              id="title" 
              {...register("title")} 
              required 
              className="bg-white border-gray-200"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="excerpt" className="text-gray-700">Extrait</Label>
            <Textarea
              id="excerpt"
              {...register("excerpt")}
              rows={3}
              placeholder="Un bref résumé de l'article..."
              className="bg-white border-gray-200"
            />
          </div>

          <div className="space-y-2">
            <Label className="text-gray-700">Contenu</Label>
            <div className="border rounded-lg overflow-hidden border-gray-200">
              <RichTextEditor
                value={watch("content") || ""}
                onChange={(value) => setValue("content", value)}
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="featured_image" className="text-gray-700">Image à la une</Label>
            <Input
              id="featured_image"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              disabled={uploading}
              className="bg-white border-gray-200"
            />
            {watch("featured_image") && (
              <img
                src={watch("featured_image")}
                alt="Aperçu"
                className="mt-2 w-full h-48 object-cover rounded-lg"
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};