import { Button } from "@/components/ui/button";

interface FormActionsProps {
  isSubmitting: boolean;
  isEditing: boolean;
}

export const FormActions = ({ isSubmitting, isEditing }: FormActionsProps) => {
  return (
    <div className="flex justify-end pt-4 border-t">
      <Button 
        type="submit" 
        className="bg-magazine-red hover:bg-red-600"
        disabled={isSubmitting}
      >
        {isSubmitting ? "En cours..." : (isEditing ? "Modifier l'article" : "Enregistrer l'article")}
      </Button>
    </div>
  );
};