import { useParams, useNavigate } from "react-router-dom";
import { ArticleForm } from "@/components/admin/ArticleForm";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { ArticleFormData } from "@/components/admin/article-form/types";

const EditArticle = () => {
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();

  // Validate ID before making the query
  const articleId = parseInt(id || "", 10);
  const isValidId = !isNaN(articleId) && articleId > 0;

  const { data: article, isLoading } = useQuery({
    queryKey: ["article", articleId],
    queryFn: async () => {
      if (!isValidId) {
        toast({
          title: "Erreur",
          description: "ID d'article invalide",
          variant: "destructive",
        });
        navigate("/admin");
        throw new Error("ID d'article invalide");
      }

      const { data, error } = await supabase
        .from("articles")
        .select("*")
        .eq("id", articleId)
        .maybeSingle();

      if (error) {
        toast({
          title: "Erreur",
          description: "Impossible de charger l'article",
          variant: "destructive",
        });
        throw error;
      }

      if (!data) {
        toast({
          title: "Erreur",
          description: "Article non trouvé",
          variant: "destructive",
        });
        navigate("/admin");
        throw new Error("Article non trouvé");
      }

      return data as ArticleFormData;
    },
    enabled: isValidId,
  });

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  if (!article) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border p-6">
      <h2 className="text-2xl font-bold mb-6">Modifier l'Article</h2>
      <ArticleForm initialData={article} />
    </div>
  );
};

export default EditArticle;