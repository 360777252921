import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Link, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { motion } from "framer-motion";

interface ArticleCardProps {
  id: string;
  title: string;
  imageUrl: string;
  category: string;
  subcategory: string;
  createdAt?: string;
  isCompact?: boolean;
}

export const ArticleCard = ({ 
  id, 
  title, 
  imageUrl, 
  category, 
  subcategory, 
  createdAt,
  isCompact = false 
}: ArticleCardProps) => {
  const [searchParams] = useSearchParams();
  const categoryParam = searchParams.get("category");
  
  const articleUrl = `/article/${id}${categoryParam ? `?category=${categoryParam}` : ''}`;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="h-full"
    >
      <Link to={articleUrl} className="h-full block group">
        <Card className="overflow-hidden h-full flex flex-col bg-white dark:bg-zinc-900 border-0 shadow-lg transition-all duration-300 hover:shadow-xl dark:shadow-none dark:border-b dark:border-gray-800">
          <CardHeader className="p-0 flex-none relative overflow-hidden">
            <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity z-10" />
            <img
              src={imageUrl}
              alt={title}
              className={`w-full object-cover transition-transform duration-500 group-hover:scale-105 ${
                isCompact ? 'h-32' : 'h-48'
              }`}
            />
          </CardHeader>
          <CardContent className={`p-4 ${isCompact ? 'space-y-1' : 'space-y-2'} flex-grow flex flex-col justify-between`}>
            <div>
              <div className="flex items-center gap-2 mb-2">
                <span className="text-magazine-red text-sm font-medium">{category}</span>
                <span className="text-gray-400">•</span>
                <span className="text-gray-600 dark:text-gray-400 text-sm">{subcategory}</span>
              </div>
              <h3 className={`font-roboto font-bold text-gray-900 dark:text-white group-hover:text-magazine-red transition-colors ${
                isCompact ? 'text-sm line-clamp-2' : 'text-lg line-clamp-3'
              }`}>
                {title}
              </h3>
            </div>
            {createdAt && (
              <p className={`text-gray-600 dark:text-gray-400 font-medium ${
                isCompact ? 'text-sm' : 'text-base'
              }`}>
                {format(new Date(createdAt), "d MMMM yyyy", { locale: fr })}
              </p>
            )}
          </CardContent>
        </Card>
      </Link>
    </motion.div>
  );
};