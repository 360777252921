import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ArticlesManager } from "@/components/admin/ArticlesManager";
import { CategoriesManager } from "@/components/admin/CategoriesManager";
import { SubcategoriesManager } from "@/components/admin/SubcategoriesManager";
import { BannerManager } from "@/components/admin/BannerManager";
import { VerticalBannerManager } from "@/components/admin/VerticalBannerManager";
import { StickyBannerManager } from "@/components/admin/StickyBannerManager";
import { Button } from "@/components/ui/button";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";

const Admin = () => {
  const location = useLocation();
  const isNewArticlePage = location.pathname.includes('nouvel-article');

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <header className="bg-white text-gray-700 p-4 flex flex-col sm:flex-row justify-between items-center gap-4 shrink-0 sticky top-0 z-50 border-b border-gray-100">
        <h1 className="text-3xl font-bold text-gray-700">Admin Dashboard</h1>
        <div className="flex gap-4">
          <Button variant="outline" className="bg-magazine-red text-white hover:bg-magazine-red/90 whitespace-nowrap" asChild>
            <Link to="/admin/nouvel-article">Nouvel Article</Link>
          </Button>
          <Button variant="outline" className="bg-magazine-red text-white hover:bg-magazine-red/90 whitespace-nowrap" asChild>
            <Link to="/">Voir le Site</Link>
          </Button>
        </div>
      </header>

      <main className="flex-1 container mx-auto p-4 sm:p-6">
        {isNewArticlePage ? (
          <Outlet />
        ) : (
          <ScrollArea className="h-[calc(100vh-5rem)] rounded-lg border border-gray-100 bg-white shadow">
            <div className="p-6 sm:p-8">
              <Tabs defaultValue="articles" className="w-full">
                <TabsList className="mb-12 flex flex-wrap gap-3 bg-gray-50">
                  <TabsTrigger value="articles" className="text-lg data-[state=active]:bg-magazine-red data-[state=active]:text-white">Articles</TabsTrigger>
                  <TabsTrigger value="categories" className="text-lg data-[state=active]:bg-magazine-red data-[state=active]:text-white">Catégories</TabsTrigger>
                  <TabsTrigger value="subcategories" className="text-lg data-[state=active]:bg-magazine-red data-[state=active]:text-white">Sous-catégories</TabsTrigger>
                  <TabsTrigger value="banner" className="text-lg data-[state=active]:bg-magazine-red data-[state=active]:text-white">Bannière</TabsTrigger>
                  <TabsTrigger value="vertical-banner" className="text-lg data-[state=active]:bg-magazine-red data-[state=active]:text-white">Bannière Verticale</TabsTrigger>
                  <TabsTrigger value="sticky-banner" className="text-lg data-[state=active]:bg-magazine-red data-[state=active]:text-white">Bannière Sticky</TabsTrigger>
                </TabsList>
                
                <TabsContent value="articles" className="mt-10">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-10">
                    <Button className="bg-magazine-red text-white hover:bg-magazine-red/90 w-full sm:w-auto" asChild>
                      <Link to="/admin/nouvel-article">Nouvel Article</Link>
                    </Button>
                  </div>
                  <ArticlesManager />
                </TabsContent>
                
                <TabsContent value="categories" className="mt-10">
                  <CategoriesManager />
                </TabsContent>
                
                <TabsContent value="subcategories" className="mt-10">
                  <SubcategoriesManager />
                </TabsContent>

                <TabsContent value="banner" className="mt-10">
                  <BannerManager />
                </TabsContent>

                <TabsContent value="vertical-banner" className="mt-10">
                  <VerticalBannerManager />
                </TabsContent>

                <TabsContent value="sticky-banner" className="mt-10">
                  <StickyBannerManager />
                </TabsContent>
              </Tabs>
            </div>
          </ScrollArea>
        )}
      </main>
    </div>
  );
};

export default Admin;
