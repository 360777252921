import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Button } from '@/components/ui/button';
import { Loader2 } from "lucide-react";
import { Card } from "@/components/ui/card";

interface PromptSectionProps {
  prompt: string;
  onPromptChange: (value: string) => void;
  onGenerate: () => void;
  generating: boolean;
}

export const PromptSection = ({
  prompt,
  onPromptChange,
  onGenerate,
  generating
}: PromptSectionProps) => {
  return (
    <Card className="p-4">
      <div className="space-y-2">
        <Label htmlFor="prompt" className="text-base font-medium">
          Prompt pour générer du contenu
        </Label>
        <div className="flex gap-4">
          <div className="flex-1">
            <Textarea
              id="prompt"
              placeholder="Décrivez l'article que vous souhaitez générer..."
              value={prompt}
              onChange={(e) => onPromptChange(e.target.value)}
              className="h-24 resize-none"
              disabled={generating}
            />
          </div>
          <Button
            onClick={onGenerate}
            disabled={generating}
            className="self-end bg-magazine-red hover:bg-red-600 text-white min-w-[120px]"
          >
            {generating ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Génération...
              </>
            ) : (
              "Générer"
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
};