import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { X } from "lucide-react";

interface StickyBannerSettings {
  image_url: string;
  link_url: string | null;
  is_active: boolean;
}

export const StickyBanner = () => {
  const [bannerSettings, setBannerSettings] = useState<StickyBannerSettings | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const fetchBannerSettings = async () => {
      try {
        const { data, error } = await supabase
          .from("sticky_banner_settings")
          .select("*")
          .order("created_at", { ascending: false })
          .limit(1)
          .maybeSingle();

        if (error) {
          console.error("Error fetching sticky banner settings:", error);
          toast({
            title: "Erreur",
            description: "Impossible de charger les paramètres de la bannière sticky",
            variant: "destructive",
          });
          return;
        }

        if (data?.is_active) {
          setBannerSettings(data);
        }
      } catch (error) {
        console.error("Error in sticky banner component:", error);
      }
    };

    fetchBannerSettings();
  }, [toast]);

  if (!bannerSettings?.image_url || !bannerSettings.is_active || !isVisible) {
    return null;
  }

  const BannerContent = () => (
    <img
      src={bannerSettings.image_url}
      alt="Sticky Banner"
      className="w-[728px] h-[90px] object-cover"
    />
  );

  return (
    <div className="fixed bottom-0 left-1/2 -translate-x-1/2 z-50 bg-white shadow-lg">
      <button
        onClick={() => setIsVisible(false)}
        className="absolute -top-2 -right-2 bg-magazine-red text-white rounded-full p-1 shadow-md hover:bg-red-600 transition-colors"
        aria-label="Fermer la bannière"
      >
        <X size={16} />
      </button>
      {bannerSettings.link_url ? (
        <a
          href={bannerSettings.link_url}
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <BannerContent />
        </a>
      ) : (
        <BannerContent />
      )}
    </div>
  );
};