import { ArticleForm } from "@/components/admin/ArticleForm";
import { LoadingMoto } from "@/components/ui/LoadingMoto";
import { useState, useEffect } from "react";

const NewArticle = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time to ensure smooth transition
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingMoto />;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border p-6">
      <h2 className="text-2xl font-bold mb-6">Nouvel Article</h2>
      <ArticleForm />
    </div>
  );
};

export default NewArticle;