import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { Edit2, Save, Trash2 } from "lucide-react";

interface CategoryTableProps {
  categories: any[];
  editingCategory: any;
  setEditingCategory: (category: any) => void;
  onEdit: (category: any) => void;
  onDelete: (category: any) => void;
}

export const CategoryTable = ({
  categories,
  editingCategory,
  setEditingCategory,
  onEdit,
  onDelete,
}: CategoryTableProps) => {
  return (
    <Card className="bg-white shadow-sm">
      <CardContent className="pt-6">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-50">
                <TableHead className="text-gray-700">Nom</TableHead>
                <TableHead className="text-gray-700">Slug</TableHead>
                <TableHead className="text-gray-700 w-[180px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {categories.map((category) => (
                <TableRow key={category.id} className="border-gray-100">
                  <TableCell className="text-gray-700">
                    {editingCategory?.id === category.id ? (
                      <Input
                        value={editingCategory.name}
                        onChange={(e) =>
                          setEditingCategory({
                            ...editingCategory,
                            name: e.target.value,
                          })
                        }
                        className="bg-white border-gray-200"
                      />
                    ) : (
                      category.name
                    )}
                  </TableCell>
                  <TableCell className="text-gray-700">
                    {editingCategory?.id === category.id ? (
                      <Input
                        value={editingCategory.slug}
                        onChange={(e) =>
                          setEditingCategory({
                            ...editingCategory,
                            slug: e.target.value,
                          })
                        }
                        className="bg-white border-gray-200"
                      />
                    ) : (
                      category.slug
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => onEdit(category)}
                        className="bg-white border-gray-200 hover:bg-gray-50"
                      >
                        {editingCategory?.id === category.id ? (
                          <Save className="h-4 w-4 text-gray-700" />
                        ) : (
                          <Edit2 className="h-4 w-4 text-gray-700" />
                        )}
                      </Button>
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() => onDelete(category)}
                        className="bg-red-50 hover:bg-red-100 border-red-200"
                      >
                        <Trash2 className="h-4 w-4 text-red-600" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};