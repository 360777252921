import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { ArticleTable } from "./articles/ArticleTable";
import { DeleteArticleDialog } from "./articles/DeleteArticleDialog";

export const ArticlesManager = () => {
  const [articles, setArticles] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    const { data, error } = await supabase
      .from("articles")
      .select(`*, subcategories(name, category:categories(name))`)
      .order("created_at", { ascending: false });

    if (error) {
      toast({
        title: "Erreur",
        description: "Impossible de charger les articles",
        variant: "destructive",
      });
      return;
    }

    setArticles(data);
  };

  const handleEdit = (id) => {
    navigate(`/admin/articles/${id}/edit`);
  };

  const confirmDelete = (article) => {
    setArticleToDelete(article);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    if (!articleToDelete) return;

    const { error } = await supabase
      .from("articles")
      .delete()
      .eq("id", articleToDelete.id);

    if (error) {
      toast({
        title: "Erreur",
        description: "Impossible de supprimer l'article",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Succès",
      description: "Article supprimé avec succès",
    });

    setDeleteDialogOpen(false);
    setArticleToDelete(null);
    fetchArticles();
  };

  const toggleHidden = async (id, currentValue) => {
    const { error } = await supabase
      .from("articles")
      .update({ hidden: !currentValue })
      .eq("id", id);

    if (error) {
      toast({
        title: "Erreur",
        description: "Impossible de modifier le statut de l'article",
        variant: "destructive",
      });
      return;
    }

    fetchArticles();
  };

  return (
    <div className="space-y-6">
      <ArticleTable
        articles={articles}
        onEdit={handleEdit}
        onDelete={confirmDelete}
        onToggleHidden={toggleHidden}
      />
      <DeleteArticleDialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        articleToDelete={articleToDelete}
        onConfirm={handleDelete}
      />
    </div>
  );
};