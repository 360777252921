import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "@/components/layout/Footer";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import AdminLogin from "./pages/AdminLogin";
import NewArticle from "./pages/NewArticle";
import EditArticle from "./pages/EditArticle";
import Article from "./pages/Article";
import { useEffect, useState } from "react";
import { supabase } from "./integrations/supabase/client";
import { LoadingMoto } from "./components/ui/LoadingMoto";

const queryClient = new QueryClient();

const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          console.log("No session found, redirecting to login");
          setIsAdmin(false);
          setLoading(false);
          navigate('/admin/login', { replace: true });
          return;
        }

        const { data: admin, error } = await supabase
          .from('admins')
          .select('*')
          .eq('id', session.user.id)
          .maybeSingle();
        
        if (error) {
          console.error("Error checking admin status:", error);
          setIsAdmin(false);
          setLoading(false);
          navigate('/admin/login', { replace: true });
          return;
        }

        if (!admin) {
          console.log("Not an admin, redirecting to login");
          setIsAdmin(false);
          setLoading(false);
          navigate('/admin/login', { replace: true });
          return;
        }

        setIsAdmin(true);
        setLoading(false);
      } catch (error) {
        console.error("Error in checkAdmin:", error);
        setIsAdmin(false);
        setLoading(false);
        navigate('/admin/login', { replace: true });
      }
    };

    checkAdmin();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(() => {
      checkAdmin();
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  if (loading) {
    return <LoadingMoto />;
  }

  if (!isAdmin) {
    return null;
  }

  return <>{children}</>;
};

const AnimatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setIsAuthenticated(!!session);
        setLoading(false);

        if (!session && location.pathname.startsWith('/admin') && location.pathname !== '/admin/login') {
          console.log("No session, redirecting to login");
          navigate('/admin/login', { replace: true });
        }
      } catch (error) {
        console.error("Error checking auth:", error);
        setIsAuthenticated(false);
        setLoading(false);
        if (location.pathname.startsWith('/admin') && location.pathname !== '/admin/login') {
          navigate('/admin/login', { replace: true });
        }
      }
    };

    checkAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setIsAuthenticated(!!session);
      if (!session && location.pathname.startsWith('/admin') && location.pathname !== '/admin/login') {
        console.log("Session changed, redirecting to login");
        navigate('/admin/login', { replace: true });
      }
    });

    return () => subscription.unsubscribe();
  }, [location.pathname, navigate]);

  if (loading) {
    return <LoadingMoto />;
  }

  return (
    <div className="flex-grow">
      <Routes location={location}>
        <Route path="/" element={<Home />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin" element={<AdminRoute><Admin /></AdminRoute>} />
        <Route path="/admin/nouvel-article" element={<AdminRoute><NewArticle /></AdminRoute>} />
        <Route path="/admin/articles/:id/edit" element={<AdminRoute><EditArticle /></AdminRoute>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Toaster />
      <Sonner />
      <BrowserRouter>
        <div className="flex flex-col min-h-screen bg-white">
          <main className="flex-grow">
            <AnimatedRoutes />
          </main>
          <Footer />
        </div>
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;