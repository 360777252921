import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { CategoryForm } from "./categories/CategoryForm";
import { CategoryTable } from "./categories/CategoryTable";
import { DeleteCategoryDialog } from "./categories/DeleteCategoryDialog";

export const CategoriesManager = () => {
  const [categories, setCategories] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const { data, error } = await supabase
      .from("categories")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) {
      toast({
        title: "Erreur",
        description: "Impossible de charger les catégories",
        variant: "destructive",
      });
      return;
    }

    setCategories(data);
  };

  const handleSubmit = async (newCategory) => {
    const { data, error } = await supabase
      .from("categories")
      .insert([newCategory])
      .select();

    if (error) {
      toast({
        title: "Erreur",
        description: "Impossible de créer la catégorie",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Succès",
      description: "Catégorie créée avec succès",
    });

    setCategories([...categories, data[0]]);
  };

  const handleEdit = async (category) => {
    if (editingCategory?.id === category.id) {
      const { error } = await supabase
        .from("categories")
        .update({ name: editingCategory.name, slug: editingCategory.slug })
        .eq("id", category.id);

      if (error) {
        toast({
          title: "Erreur",
          description: "Impossible de modifier la catégorie",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Succès",
        description: "Catégorie modifiée avec succès",
      });

      setEditingCategory(null);
      fetchCategories();
    } else {
      setEditingCategory(category);
    }
  };

  const confirmDelete = (category) => {
    setCategoryToDelete(category);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    if (!categoryToDelete) return;

    const { error } = await supabase
      .from("categories")
      .delete()
      .eq("id", categoryToDelete.id);

    if (error) {
      toast({
        title: "Erreur",
        description: "Impossible de supprimer la catégorie",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Succès",
      description: "Catégorie supprimée avec succès",
    });

    setDeleteDialogOpen(false);
    setCategoryToDelete(null);
    fetchCategories();
  };

  return (
    <div className="space-y-8">
      <CategoryForm onSubmit={handleSubmit} />
      <CategoryTable
        categories={categories}
        editingCategory={editingCategory}
        setEditingCategory={setEditingCategory}
        onEdit={handleEdit}
        onDelete={confirmDelete}
      />
      <DeleteCategoryDialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        categoryToDelete={categoryToDelete}
        onConfirm={handleDelete}
      />
    </div>
  );
};