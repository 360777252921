import { Separator } from "@/components/ui/separator";
import { ArticleGrid } from "@/components/ArticleGrid";
import { motion } from "framer-motion";
import { Banner } from "./Banner";
import { FeaturedSection } from "./FeaturedSection";

interface MainContentProps {
  selectedCategory: string | null;
  articles: any[];
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const MainContent = ({ 
  selectedCategory, 
  articles,
  currentPage,
  totalPages,
  onPageChange
}: MainContentProps) => {
  if (selectedCategory) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto py-8"
      >
        <h2 className="text-2xl font-bold text-foreground text-left mb-4">
          {selectedCategory}
        </h2>
        <Separator className="bg-magazine-red h-1" />
        <ArticleGrid 
          articles={articles} 
          isCategory={true} 
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </motion.div>
    );
  }

  return (
    <div className="w-full">
      <Banner />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto py-4"
      >
        <FeaturedSection articles={articles} />
      </motion.div>
    </div>
  );
};